import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MSchoolCard = (props) => {
  return (
    <div className="mschoolcard">
      <div className="row">
        <div className="col">
          <div className="mschoolcard__image">
            <LazyLoadImage src={props.image} alt={props.imageAlt} />
          </div>
        </div>
        <div className="col">
          <div className="mschoolcard__content">
            <h3>{props.header}</h3>
            <h6 className="mschoolcard__subheader">{props.subheader}</h6> 
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MSchoolCard;
