import React, { useEffect } from 'react';
import Instafeed from 'instafeed.js';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CourseGridItem from '../components/coursegriditem';
import MSchoolCard from '../components/school/mschoolcard';
import SchedulePopup from '../components/schedulepopup';
import settings from '../../settings';
import TopBanner from '../components/mschool/topbanner';
import {hotelsUltimateSouthCarolina,hotelsUltimateCarifornia} from '../helpers/hotels.js';

//images
const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/ultimate-benefits-top-banner.jpg`;
const performancecenterstudent = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/bmw-performance-center-student.jpg`;

const interstatebraking = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/interstate-braking-box.jpg`;
const doublelanechange = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/double-lane-box.jpg`;
const panicbraking = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/panic-braking-box.jpg`;
const handlingcourse = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/handling-course-box.jpg`;
const wetskidpad = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/wet-skid-pad-box.jpg`;

const interstatebrakingMobile = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/interstate-braking-box.jpg`;
const doublelanechangeMobile = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/double-lane-box.jpg`;
const panicbrakingMobile = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/panic-braking-box.jpg`;
const handlingcourseMobile = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/handling-course-box.jpg`;
const wetskidpadMobile = `${settings.IMAGES_BASE_URL}/images/ultimatebenefits/wet-skid-pad-box.jpg`;


const UltimateBenefitsPage = () => {
  useEffect(()=> {
    const feed = new Instafeed({
      get: 'user',
      userId: '3976399346',
      accessToken: '3976399346.1677ed0.4a06e22f7bc04dd2a5e2bc7c4d19d344',
      target: 'instafeed',
      limit: 3,
      resolution: 'standard_resolution',
      template:
        '<div class="instagramcard"><div class="instagramcard__header"> <div class="left"><a href={{link}} target="_blank" rel="noopener noreferrer" class="instagramcard__viewmore"> <img src={{model.user.profile_picture}} class="instagramcard__profile" alt={{caption}} width={{width}} height={{height}} /> <span class="instagramcard__username">bmwperformancedrivingschools</span> </a> </div>  <a href={{link}} target="_blank" rel="noopener noreferrer" class="instagramcard__viewmore"> <button class="instagramcard__button">View Profile </button> </a> </div> <img src={{image}} alt="Instagram" class="instagram__image" /> <a href={{link}} target="_blank" rel="noopener noreferrer" class="instagramcard__viewmore"> View More on Instagram </a> <hr /> <span class="instagramcard__likes">{{likes}} likes</span> <p class="instagramcard__caption">{{caption}}</p></div>'
    });
    feed.run();
  },[])
  const TitleComponent = () => { 
    return (
        <><span>BMW</span> <span className='bold'>ULTIMATE BENEFITS</span><sup className="title__sup">™</sup></>
  )
}

const Agenda  = () => {
  return (
    <div>
    <SchedulePopup btnText="View Agenda">
    <div className="two_days_schedule">
        <div className="mr_10">
          <div>
            <h6>ONE-DAY DRIVER'S SCHOOL</h6>
            <small>Sample schedule subject to change</small>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">8:00 am-8:30 am</p>
            <p className="schedule__item__event">
              Arrival, registration
            </p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">8:30 am-9:30 am</p>
            <p className="schedule__item__event">
              Class discussion
            </p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">9:30 am-10:00 am</p>
            <p className="schedule__item__event">Proper seating / Warm-up slalom</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">10:00 am-12:00 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">12:00 pm-1:00 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">1:00 pm-4:00 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">4:00 pm-4:15 pm</p>
            <p className="schedule__item__event">Closing</p>
          </div>
        </div>
        <div>
          <div>
            <h6>ONE-DAY DRIVER'S SCHOOL - <br /> THERMAL SUMMER <br /> SCHEDULE</h6>
            <small>Summer hours for our California location only, <br /> valid
              from June 1- September 30.
            </small>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:00 am-6:30 am</p>
            <p className="schedule__item__event">Arrival, registration</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:30 am-7:30 am</p>
            <p className="schedule__item__event">Class discussion</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">7:30 am-12:30 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:30 pm-1:30 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
        </div>
      </div>
  </SchedulePopup>
  </div>
  )
}
const HotelItem = ({img,title,link,code}) => {
  return (
    <div className="hotels_item">
       {img && <img src={img} alt={title} />}
       {title && <h4>{title} {code &&<><br /><span>Code: {code}</span></>}</h4>}
        {<a href={link} target="_blank" rel="noopener noreferrer"><button className="btn btn-bordered">Book Now</button></a>}
      </div>
  )
}
    return (
      <Layout>
        <SEO title="BMW Performance School | Ultimate Benefits" />
        <div className="page__ultimatebenefits">
        <TopBanner
				  image={topbannerDesktop}
					mobileImage={topbannerDesktop}
					header={<TitleComponent />}
					subheader="LOYALTY HAS ITS REWARDS">
              <p>
                Join us to experience the BMW Performance Driving School, with
                locations on the East and West coasts, and test your driving
                skills on the track. It’ll be the most fun you’ll ever have in
                school.
              </p>
              <p>
                Classes run frequently but fill up quickly, so book now to ensure
                you reserve your desired spot before your invitation expires.
              </p>
              <button className="btn btn-blue">Call 888-345-4269 to book now</button>
					</TopBanner>

          <MSchoolCard
            image={performancecenterstudent}
            imageAlt="BMW Performance Center Student"
            header="One-day program"
            subheader="EXPERIENCE IT ALL"
          >
            <p>
            Take a complimentary one-day course at the BMW Performance Driving School in California or South Carolina, where you’ll test your limits. Like any BMW, this offer moves fast and is available to loyal BMW customers within the first year of the purchase of a new BMW.
            </p>
            <Agenda />
            <button className="btn btn-blue">Call 888-345-4269 to book now</button>


            <p className="note">
            *Offer is available to current, original owners of a “New” BMW who previously have owned one or more “New” or “Certified Pre-Owned” BMWs. Offer is valid for one year after delivery date of a new BMW. A Performance Driving School Course may be taken later, but must be booked before the 12-month window ends. Offer applies to a “One-Day Driver’s School” course. Approximate market value ($999) subject to change without prior notice. Limited to one class per year per customer. Value may be applied to any other BMW Performance Driving School class. Excludes airfare, incidental costs, meals and additional charges. Offer is non-transferable. 
            </p>
          </MSchoolCard>
          <section className="coursehiglight">
            <div className="container">
              <div className="coursehighlight__header">
                <h2>Course Highlights</h2>
              </div>
            </div>
            <div className="coursegrid">
              <div className="row coursegrid__top">
                <CourseGridItem
                  cardImage={interstatebraking}
                  cardMobileImage={interstatebrakingMobile}
                  cardTitle="INTERSTATE BRAKING"
                  cardText="Students start out driving steady 70 mph in a straight line. In their path is a box made of cones, and students must decide both at what point to hit the brakes and how much brake pressure is needed in order to stop correctly, all while keeping the car under control."
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="50"
                />
                <CourseGridItem
                  cardImage={doublelanechange}
                  cardMobileImage={doublelanechangeMobile}
                  cardTitle="DOUBLE LANE CHANGE"
                  cardText="Students will explore how a car handles changes in direction, as their vehicle’s weight shifts through a series of left to right turns. Avoid hitting cones, and you’ll win the day."
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="80"
                />
              </div>
              <div className="row">
                <div className="coursewrapper-one row">
                  <CourseGridItem
                    cardImage={panicbraking}
                    cardMobileImage={panicbrakingMobile}
                    cardTitle="PANIC BRAKING"
                    cardText="Learn to brake hard from speeds up to 55 mph while navigating a corner. Sounds easy: it isn’t. But our instructors will give you pointers throughout."
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width="80"
                  />
                  <CourseGridItem
                    cardImage={handlingcourse}
                    cardMobileImage={handlingcourseMobile}
                    cardTitle="HANDLING COURSE"
                    cardText="Students will get an overall feel for a BMW as they drive through our handling course. Developing a feel for the limit of traction while accelerating, braking and cornering, it’s an exercise aimed at overall car control."
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width="80"
                  />
                </div>
                <div className="coursewrapper-two row">
                  <CourseGridItem
                    cardImage={wetskidpad}
                    cardMobileImage={wetskidpadMobile}
                    cardTitle="WET SKID PAD"
                    cardText="A polished concrete skid pad offers up the perfect spot to learn how to handle your car at the limit of grip. You’ll learn to feel when the car starts to slip and how to control it through a drift. Yea, it’s a blast."
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width="60"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="location" 					
   >
            <div className="container">
              <div className="location__header">
                <h2>LOCATIONS AND AMENITIES</h2>
                <p>
                  Whether choosing our location on the East or West coast, you’ll find beautiful surroundings, an
                  attentive staff and of course, plenty of driving pleasure.
                </p>
              </div>
            </div>
            <div className="location__type">
              <div className="location__spartanburg"        
                   style={{backgroundImage: `url(${settings.IMAGES_BASE_URL}/images/ultimatebenefits/sc-banner.jpg`,}}
              >
                <div className="container">
                  <div className="location__spartanburg__content">
                    <h3>Spartanburg, South Carolina</h3>
                    <p>
                      Make a vacation out of your visit to the Performance Center in Greenville-Spartanburg. With
                      numerous hotels, nearby golf courses and charming downtown Greenville a short drive away, there is
                      plenty to explore when not behind the wheel of an Ultimate Driving Machine<sup>®</sup>.
                    </p>
                    <a href="https://www.visitspartanburg.com/" target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-bordered">Learn More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="location__type">
              <div className="location__thermal"        
                   style={{backgroundImage: `url(${settings.IMAGES_BASE_URL}/images/ultimatebenefits/cali-banner.jpg`,}}
              >
                <div className="container">
                  <div className="location__thermal__content">
                    <h3>Thermal, California</h3>
                    <p>
                      With its year-round warm, sunny weather and vibrant nightlife, Thermal offers something for
                      everyone to enjoy. The beautiful backdrop of snow-capped mountains prevail over much of the area,
                      and its close proximity to numerous hotels, golf courses and resort-like Palm Springs offers a
                      chance to relax.
                    </p>
                    <a href="https://www.visitgreaterpalmsprings.com/" target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-bordered">Learn More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hotels">
            <div className="header__content">
              <h2>HOTEL PARTNERS</h2>
              <p>
                With all that excitement out on the track, you’re going to need a place to recharge. Select one of our hotel partners during your stay, and you’ll enjoy exclusive savings.
              </p>
            </div>
            {/* SOUTH CAROLINA */}
            <h3>HOTEL AFFILIATES - SOUTH CAROLINA</h3>
            <div className="container hotels__list hotels__south_california">
            {hotelsUltimateSouthCarolina && hotelsUltimateSouthCarolina.map(({img,title,link,code}) => {
                return (
                  <HotelItem img={img} title={title} link={link} code={code} />
                )
              })}
            </div>
            {/* END SOUTH CAROLINA */}
            <h3>HOTEL AFFILIATES - CALIFORNIA</h3>
            <div className="container hotels__list hotels__california">
            {hotelsUltimateCarifornia && hotelsUltimateCarifornia.map(({img,title,link,code}) => {
                return (
                    <HotelItem img={img} title={title} link={link} code={code} />
                )
              })}
            </div>
          </section>

        </div>
      </Layout>
    );
  
}
export default UltimateBenefitsPage;
